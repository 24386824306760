.InStockLink {
	color: white;
	text-decoration: none;
	font-weight: bold;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 50px 0 0 50px;
	padding-left: 30px;
}
.InStockLinkAdmin {
	color: #C12393;
	text-decoration: none;
	font-weight: bold;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 50px 0 0 50px;
	padding-left: 30px;
}
.InStockLinkSelected {
	background-color: rgba(255,255,255,0.18);
}
.InStockLinkAdminSelected {
	background-color: #C12393;
	color: white;
}
.InStockLinkAdminSelected .InStockLinkIcon {
	color: white;
}
.InStockLink:hover {
	background-color: rgba(255,255,255,0.18);
}
.InStockLinkAdmin:hover {
	background-color: #C12393;
	color: white;
}
.InStockLinkAdmin:hover .InStockLinkIcon {
	color: white;
}
.InStockLinkIcon {
	color: #C12393;
	font-size:  2.5rem !important;
	vertical-align: middle;
	margin-right: 15px;
}
