* {
  font-family: 'Poppins', sans-serif !important;
}
body {
	margin: 0px;
	background-color: white;
}
.pg {
	background: linear-gradient(179.98deg, #5E085A 45.9%, #C12393 99.99%);	
}
.pb {
	background-color: #5E085A;
}
.bs {
	box-shadow: 4px 0px 20px rgba(255, 255, 255, 0.5);
}
@font-face {
	font-family: bootstrapIcons;
	src: url(bootstrap-icons/font/fonts/bootstrap-icons.woff)
}
.instock-view {
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	border-radius: 15px;
	padding: 30px;
}
.MuiDialog-paper {
	background-color: inherit !important;
}
.MuiMenu-list {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.FormHeader {
	color: #C12393;
	font-size: 20px !important;
	font-weight: bold !important;
}
.ImagePreview:hover {
	background: #ABABAB;	
}
